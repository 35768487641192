/* Restore Choices.js default styles */
@import "choices.js/public/assets/styles/choices.min.css";

/* Custom modifications */
.choices__list--dropdown {
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 4px;
}

.choices__list--dropdown .choices__item--selectable {
  padding: 10px 12px;
  border-bottom: 1px solid #e0e0ff;
}

.choices__list--dropdown .choices__item--selectable:last-child {
  border-bottom: none;
}

.choices__list--dropdown .choices__item--selectable .is-highlighted {
  background-color: #f0f5ff;
  color: #007bff;
}
.choices__list--multiple .choices__item--selectable .option-description {
  display: none;
}

.choices__list--dropdown .choices__item--selectable .option-description {
  font-size: 0.8em;
  color: #666;
  float: right;
  /* margin-left: 5px; */
}

.choices__inner {
  min-height: 44px;
  border: 1px solid #007bff;
  border-radius: 4px;
}

/* Fix for disturbed layout */
.form-control {
  height: auto;
  min-height: 44px;
}

/* Style for selected items */
.choices__list--multiple .choices__item {
  background-color: #007bff;
  border: 1px solid #0056b3;
}

/* Hover effect for dropdown items */
.choices__list--dropdown .choices__item--selectable:hover {
  background-color: #e6f3ff;
}

/* Custom styles for Choices.js dropdown */
.choices__list--dropdown {
  background-color: #e6f3ff !important; /* Light blue background */
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #007bff !important; /* Darker blue for highlighted items */
  color: white !important;
}

.choices__list--dropdown .choices__item--selectable {
  padding: 8px !important;
}
