.stat {
  background: $stat-bg;
  color: $stat-icon-color;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;

  svg {
    width: 18px;
    height: 18px;
    color: inherit !important;
    stroke-width: 1.5;
  }
  
  i {
    font-size: 18px;
    color: inherit;
  }
}