body[data-sidebar-layout=compact] {

  [data-simplebar] {
    width: $sidebar-compact-width;
    position: relative;
  }

  // Hide elements
  .sidebar-header,
  .sidebar-badge,
  .sidebar-user,
  .sidebar-cta,
  .sidebar-nav > .sidebar-item > .sidebar-link:before,
  .sidebar-nav > .sidebar-item > .sidebar-link:after,
  .sidebar-nav > .sidebar-item > .sidebar-dropdown,
  .sidebar-nav > .sidebar-item .sidebar-link span {
    display: none;
  }

  .sidebar-brand {
    text-align: center;
  }

  .simplebar-vertical,
  .simplebar-horizontal {
    visibility: hidden !important;
  }

  // Allow overflow
  .simplebar-wrapper,
  .simplebar-height-auto-observer-wrapper,
  .simplebar-mask,
  .simplebar-content-wrapper {
    overflow: visible !important;
  }

  // Remove Bootstrap's default animation
  .sidebar-dropdown.collapsing {
    transition: none;
    overflow: visible;
    height: auto;
  }

  // Sidebar
  .sidebar {
    min-width: $sidebar-compact-width;
    max-width: $sidebar-compact-width;
    z-index: 5;
  }

  // Sidebar brand
  .sidebar-brand-text {
    display: none;
  }
  
  .sidebar-brand-icon {
    display: block;
  }

  // Sidebar item
  .sidebar-item {
    position: relative;
  }

  // Sidebar dropdown
  .sidebar-nav > .sidebar-item > .sidebar-dropdown {
    display: none;
    overflow: visible;
    position: absolute;
    z-index: 1;
    width: $sidebar-compact-dropdown-width;
    box-shadow: $sidebar-compact-dropdown-box-shadow;
    border-radius: $sidebar-compact-dropdown-border-radius;
    background: $sidebar-compact-dropdown-background;
    padding: $sidebar-compact-dropdown-padding;
    top: 0;

    // Make sure hover state is not lost
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 16px;
      height: 100%;
    }
  }

  // Sidebar dropdown items
  .sidebar-nav > .sidebar-item > .sidebar-dropdown > .sidebar-item {
    .sidebar-link {
      padding: $sidebar-compact-dropdown-link-padding;
      color: $sidebar-compact-dropdown-link-color;
    }

    .sidebar-link:hover {
      color: $sidebar-compact-dropdown-link-hover-color;
    }

    .sidebar-link:before {
      left: -10px;
    }

    .sidebar-item.active .sidebar-link,
    .sidebar-item.active .sidebar-link:hover {
      color: $sidebar-compact-dropdown-link-active-color;
    }
  }

  // Dropdown children
  .sidebar-nav > .sidebar-item > .sidebar-dropdown {
    .sidebar-dropdown {
      .sidebar-link {
        padding: $sidebar-compact-dropdown-child-link-padding;
      }

      .sidebar-dropdown {
        .sidebar-link {
          padding: $sidebar-compact-dropdown-child-second-link-padding;
        }
      }
    }
  }

  // Chevron icons
  .sidebar .sidebar-dropdown [data-bs-toggle="collapse"]:after {
    top: 0.9rem;
  }
  
  .sidebar .sidebar-dropdown [aria-expanded="true"]:after,
  .sidebar .sidebar-dropdown [data-bs-toggle="collapse"]:not(.collapsed):after {
    top: 1.1rem;
  }

  // Hover
  .sidebar-nav > .sidebar-item:hover > .sidebar-dropdown {
    display: block;
  }
}

// Collapse behavior if compact sidebar is on left side
body[data-sidebar-layout=compact]:not([data-sidebar-position=right]) {
  .sidebar-nav > .sidebar-item > .sidebar-dropdown {
    left: $sidebar-compact-width + 12px;;

    &:before {
      left: -16px;
    }
  }

  // Sidebar collapse
  .sidebar {
    &.collapsed {
      margin-left: -$sidebar-compact-width;
    }

    @include media-breakpoint-down(lg) {
      // Sidebar default state (on mobile)
      margin-left: -$sidebar-compact-width;

      // Sidebar collapsed state (on mobile)
      &.collapsed {
        margin-left: 0;
      }
    }
  }
}

// Collapse behavior if compact sidebar is on right side
body[data-sidebar-layout=compact][data-sidebar-position=right] {
  .sidebar-nav > .sidebar-item > .sidebar-dropdown {
    right: $sidebar-compact-width + 12px;

    &:before {
      right: -16px;
    }
  }

  // Sidebar collapse
  .sidebar {
    &.collapsed {
      margin-right: -$sidebar-compact-width;
    }

    @include media-breakpoint-down(lg) {
      // Sidebar default state (on mobile)
      margin-right: -$sidebar-compact-width;

      // Sidebar collapsed state (on mobile)
      &.collapsed {
        margin-right: 0;
      }
    }
  }
}