.simplebar-scrollbar:before {
  @include theme("theme", "background", $simplebar-scrollbar-bg);
}

.simplebar-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 0 !important;
}

[data-simplebar] {
  position: sticky;
  top: 0;
  left: 0;
  width: $sidebar-width;
}