body {
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  max-width: 100%;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wrapper {
  min-height: 100%;
  position: relative;
}
.body_content {
  padding-bottom: 70px; /* Height of the footer */
}

.opensans_regular.popup_ads_bx01_bx02 p.alt02 {
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}



.popup_ads_bx01_bx06_btn02 {
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.popup_ads_bx01_bx01 {
  position: relative;
}
.popup_ads_bx01_bx01_close {
  background: none;
  border: 0;
  position: absolute;
  right: -35px;
  top: -40px;
}

.popup_ads_bx01_bx02 p.alt01 {
  font-size: 45px;
  margin: 0;
  color: #a80303;
}
.popup_ads_bx01_bx02 p.alt02 {
  font-size: 22px;
  color: #000;
  margin: 0;
}

.popup_ads_bx01_bx03 {
  margin: 10px 0 15px 0;
}
.popup_ads_bx01_bx05 p.alt01 {
  font-size: 30px;
  margin: 0;
  color: #025ba6;
}
.popup_ads_bx01_bx06 {
  margin: 15px 0 0 0;
}

.popup_ads_bx01_bx06_btn01 {
  background: #a80303 !important;
  width: 100% !important;
  cursor: pointer;
  font-size: 45px !important;
  color: #fff !important;
  padding: 8px 0 8px 0 !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3 ) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3) !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  text-align: center !important;
  border: 0 !important;
  outline: 0 !important;
}
.popup_ads_bx01_bx06_btn01:hover {
  background: #025ba6;
  color: #fff;
}
.popup_ads_bx01_bx06_btn02 {
	background: #a80303 !important;
	width: 100% !important;
	cursor: pointer;
	font-size: 45px !important;
	color: #fff !important;
	padding: 8px 0 8px 0 !important;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;
	-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3) !important;
	-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3 ) !important;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3) !important;
	-webkit-border-radius: 15px !important;
	-moz-border-radius: 15px !important;
	border-radius: 15px !important;
	text-align: center !important;
	border: 0 !important;
	outline: 0 !important;
}
.popup_ads_bx01_bx06_btn02:hover {
  background: #686868;
  color: #fff;
}

.popup_ads_bx01_bx07 {
  position: relative;
}
/*.popup_ads_bx01_bx07_close{font-size:18px;color:#000;background:none;border:0;}*/
.popup_ads_bx01_bx07_btn01 {
  background: #fff;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  color: #000;
  padding: 5px 0 5px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  text-align: center;
  border: 5px solid #000;
  outline: 0;
  position: relative;
  left: 0;
  top: 42px;
}
.popup_ads_bx01_bx07_btn01:hover {
  background: #000;
  color: #fff;
}

.modal-dialog {
  border: 5px solid #000;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin-top: 40px;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 481px) {
  .popup_ads_bx01_bx02 p.alt01 {
    font-size: 35px;
  }
  .popup_ads_bx01_bx02 p.alt02 {
    font-size: 14px;
  }

  .popup_ads_bx01_bx05 p.alt01 {
    font-size: 24px;
  }

  .popup_ads_bx01_bx06_btn01 {
    font-size: 35px;
  }
  .popup_ads_bx01_bx06_btn02 {
    font-size: 35px;
  }

  .popup_ads_bx01_bx01_close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -17px;
    top: -27px;
  }

  .popup_ads_bx01_bx07_btn01 {
    font-size: 14px;
    border: 2px solid #000;
    top: 35px;
  }

  .modal-dialog {
    border: 2px solid #000;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
}
