a.badge,
.badge {
  color: $static-white;
}

a.badge.bg-light,
.badge.bg-light {
  color: $dark;
}

@each $color, $value in $theme-colors {
  a.badge-#{$color}-light,
  .badge-#{$color}-light {
    @include badge-variant(rgba($value, 0.15));
    color: $value;
  }
}