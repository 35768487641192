.input-group-navbar {
  direction: ltr;

  .form-control,
  .btn {
    height: $input-height-lg;
    background: $navbar-input-bg;
    box-shadow: none;
    border: 0;
    padding: $input-padding-y-lg $spacer;
    border-radius: 20px;

    &:focus {
      background: $navbar-input-bg;
      box-shadow: none;
      outline: 0;
    }
  }

  .btn {
    color: $gray-600;

    .feather {
      width: 20px;
      height: 20px;
    }
  }
}

.input-group-sm > .form-select {
  padding-right: 1.8rem;
}