body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  /* margin: 20px; */
  background-color: #f9f9f9;
}
h1,
h2,
h3 {
  color: #0056b3;
}
pre {
  background-color: #eef;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}
.code {
  font-family: "Courier New", Courier, monospace;
  color: #d14;
}
.text {
  color: #333;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
.logo {
  text-align: center;
  margin-bottom: 20px;
}
