html,body {margin:0;padding:0;height:100%;}
body{background: #fff;font-family: Arial, Helvetica, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
img{max-width:100%;}
*, *:before, *:after {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
.wrapper{min-height:100%;position:relative;}
.body_content{padding-bottom:70px;	/* Height of the footer */}

.sourcesanspro_bold{font-weight: normal; font-style: normal;line-height:normal; font-family: 'Source Sans Pro', sans-serif; font-weight: 700;}

.opensans_regular,.off01_bx01{font-weight: normal; font-style: normal;line-height:normal; font-family: 'Open Sans', sans-serif; font-weight: 400;}


.row-eq-height {display: -webkit-box;display: -webkit-flex;display: -ms-flexbox;display: flex;}
.row-eq-height [class*="col-"] {padding-top: 0px;padding-bottom: 0px;}

.row-eq-height01 {display: -webkit-box;display: -webkit-flex;display: -ms-flexbox;display: flex; align-items: center;}
.row-eq-height01 [class*="col-"] {padding-top: 0px;padding-bottom: 0px;}

.row-container {display: table;width: 100%;}
.row-container-col {display: table-cell;}

/*For Mobile*/
/*.mt-15{margin-top:15px;}*/
/*.wd-100{width:100%;}*/
/*For Mobile*/

/*Offer01 Start*/
.off01_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}

.off01_bx01_bx01_bx01{background:#fff;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:2px solid #0083c2;}
.off01_bx01_bx01_bx01_tp_bx01{background:#0083c2;font-size:33px;color:#fff;-webkit-border-top-left-radius: 12px;-webkit-border-bottom-left-radius: 12px;-moz-border-radius-topleft: 12px;-moz-border-radius-bottomleft: 12px;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
.off01_bx01_bx01_bx01_tp_bx01 p.alt01{margin:4px 0 0 0;}
.off01_bx01_bx01_bx01_tp_bx02 p.alt01{font-size:16px;font-weight:bold;color:#000;margin-top:15px;}
.off01_bx01_bx01_bx01_tp_bx03 img.alt01{margin-top:15px;}

.off01_bx01_bx01_bx01_bt_bx01{background:#0083c2;font-size:33px;color:#fff;-webkit-border-top-left-radius: 12px;-webkit-border-bottom-left-radius: 12px;-moz-border-radius-topleft: 12px;-moz-border-radius-bottomleft: 12px;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
.off01_bx01_bx01_bx01_bt_bx01 p.alt01{margin-top:45px;}
.off01_bx01_bx01_bx01_bt_bx02 img.alt01{margin-top:15px;}
.off01_bx01_bx01_bx01_bt_bx03 p.alt01{font-size:16px;font-weight:bold;color:#000;margin-top:15px;}
.off01_bx01_bx01_bx01_bt_bx03 p.alt02{font-size:14px;color:#000;line-height:24px;}
.off01_bx01_bx01_bx01_bt_bx04 img.alt01{margin-top:15px;}
.off01_bx01_bx01_bx01_bt_bx04 img.alt02{margin-top:15px;}

.off01_bx01_bx01_bx02{font-size:24px;color:#0083c2;margin-top:25px;}
.off01_bx01_bx01_bx02 img{position:relative;top:-3px;}
.off01_bx01_bx01_bx02 a{text-decoration:none;color:#0083c2;}

.off01_bx01_bx01_bx03_btn01{background: #42a910;cursor: pointer;font-size: 31px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 0 8px 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.off01_bx01_bx01_bx03_btn01:hover{background: #0083c2;}
/*Offer01 End*/

/*Offer02 Start*/
.off02_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}

.off02_bx01_bx01_bx01{background:#fff;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:2px solid #0083c2;}
.off02_bx01_bx01_bx01_tp_bx01{background:#0083c2;font-size:33px;color:#fff;-webkit-border-top-left-radius: 12px;-webkit-border-bottom-left-radius: 12px;-moz-border-radius-topleft: 12px;-moz-border-radius-bottomleft: 12px;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
.off02_bx01_bx01_bx01_tp_bx01 p.alt01{margin:4px 0 0 0;}
.off02_bx01_bx01_bx01_tp_bx02 p.alt01{font-size:16px;font-weight:bold;color:#000;margin-top:15px;}
.off02_bx01_bx01_bx01_tp_bx03 img.alt01{margin-top:15px;}

.off02_bx01_bx01_bx01_bt_bx01{background:#0083c2;font-size:33px;color:#fff;-webkit-border-top-left-radius: 12px;-webkit-border-bottom-left-radius: 12px;-moz-border-radius-topleft: 12px;-moz-border-radius-bottomleft: 12px;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
/*.off02_bx01_bx01_bx01_bt_bx01 p.alt01{margin-top:45px;}*/
.off02_bx01_bx01_bx01_bt_bx01 p.alt01{width:100%;text-align:center;margin:0;}
.off02_bx01_bx01_bx01_bt_bx02 img.alt01{margin-top:15px;}
.off02_bx01_bx01_bx01_bt_bx03 p.alt01{font-size:16px;font-weight:bold;color:#000;margin-top:15px;}
.off02_bx01_bx01_bx01_bt_bx03 p.alt02{font-size:14px;color:#000;line-height:24px;}
.off02_bx01_bx01_bx01_bt_bx04 img.alt01{margin-top:15px;}
.off02_bx01_bx01_bx01_bt_bx04 img.alt02{margin-top:15px;}

.off02_bx01_bx01_bx02{font-size:18px;font-weight:bold;color:#0083c2;margin-top:25px;}
.off02_bx01_bx01_bx02 img{position:relative;top:-3px;}
.off02_bx01_bx01_bx02 a{text-decoration:none;color:#880015;}

.off02_bx01_bx01_bx03_btn01{background: #42a910;cursor: pointer;font-size: 31px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 0 8px 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.off02_bx01_bx01_bx03_btn01:hover{background: #0083c2;}
/*Offer02 End*/

/*Offer03 Start*/
.off03_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off03_bx01_bx01_bx01{background:#fff;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:2px solid #0083c2;}

.off03_bx01_bx01_bx02_btn01{background: #42a910;cursor: pointer;font-size: 31px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 0 8px 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.off03_bx01_bx01_bx02_btn01:hover{background: #0083c2;}
/*Offer03 End*/

/*Offer04 Start*/
.off04_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off04_bx01_bx01_bx01{background:#fff;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:2px solid #0083c2;}
.off04_bx01_bx01_bx01_bx01{width:300px;position:relative;}
.off04_bx01_bx01_bx01_bx01 img{border:1px solid;}
.off04_bx01_bx01_bx01_bx01 .close_button {background:#999;color:#0000ff;text-decoration: none;margin: 0;padding: 0;border: 0;cursor: pointer;position: absolute;z-index: 100;top: 0px; bottom: auto;margin-top: 1px;right: 0px;left: auto;text-align: right;margin-right: 1px;display: block;width: 20px;height:20px;}


.off04_bx01_bx01_bx02_btn01{background: #42a910;cursor: pointer;font-size: 31px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 0 8px 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.off04_bx01_bx01_bx02_btn01:hover{background: #0083c2;}
/*Offer04 End*/

/*Offer05 Start*/
.off05_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off05_bx01_bx01_bx01{background:#fff;border:1px solid #0083c2;}
.off05_bx01_bx01_bx01_bx01{font-weight:bold;color:#ff0000;}
.off05_bx01_bx01_bx01_bx03_rt img.alt01{margin-top:15px;}

.off05_bx01_bx01_bx02_btn01{background: #42a910;cursor: pointer;font-size: 31px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 0 8px 0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.off05_bx01_bx01_bx02_btn01:hover{background: #0083c2;}
/*Offer05 End*/

/*Offer06 Start*/
.off06_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off06_bx01_bx01_bx01{background:#fff;margin-bottom:15px;border:1px solid #0083c2;}
.off06_bx01_bx01_bx01_bx01{font-weight:bold;color:#ff0000;}
.off06_bx01_bx01_bx01_bx03_rt img.alt01{margin-top:15px;}
/*Offer06 End*/

/*Offer07 Start*/
.off07_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off07_bx01_bx01_bx01{background:#fff;margin-bottom:15px;border:1px solid #0083c2;}
.off07_bx01_bx01_bx01_bx02 p.alt01{font-weight:bold;color:#ff0000;margin:0;}
.off07_bx01_bx01_bx01_bx02 p.alt02{font-size:14px;margin:0;}
.off07_bx01_bx01_bx01_bx03 img.alt01{margin-top:15px;}
/*Offer07 End*/

/*Offer08 Start*/
.off08_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off08_bx01_bx01_bx01{background:#fff;margin-bottom:15px;border:1px solid #0083c2;}
.off08_bx01_bx01_bx01_bx02 p.alt01{font-size:14px;font-weight:bold;color:#ff0000;margin:0;}
.off08_bx01_bx01_bx01_bx03 img.alt01{width:25px;height:25px;}
/*Offer08 End*/

/*Offer09 Start*/
.off09_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.off09_bx01_bx01_bx01{background:#fff;margin-bottom:15px;border:1px solid #0083c2;}
.off09_bx01_bx01_bx01_bx02 p.alt01{font-size:14px;font-weight:bold;color:#ff0000;margin:0;}
.off09_bx01_bx01_bx01_bx03 img.alt01{width:25px;height:25px;}
/*Offer09 End*/

/*Select Format Start*/
.select_format_bx01_bx01{background:#fff;-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;border:1px solid #42a910;}
.select_format_bx01_bx01 p.alt01{font-size:18px;font-weight:bold;color:#000;}
.select_format_bx01_bx01_btn01{background: #42a910;cursor: pointer;font-size: 18px;font-weight:bold;margin-top:25px;color:#fff;padding:8px 15px 8px 15px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);-webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;text-align:center;border:0;outline:0;}
.select_format_bx01_bx01_btn01:hover{background: #0083c2;}
/*Select Format End*/

/*Select Format Template Start*/
.select_format_template_bx01{margin-top:25px;}
.select_format_template_bx01_lt{margin-top:25px;}
.select_format_template_bx01_lt p.alt01{font-size:14px;color:#ccc;}
.select_format_template_bx01_rt{background:#ccc;margin-top:25px;}
.select_format_template_bx01_rt p.alt01{font-size:18px;color:#999;margin-top:25px;}
.select_format_template_bx01_rt ul li{font-size:14px;color:#999;margin-top:15px;}
.select_format_template_ftr{font-size:14px;color:#ccc;margin-top:25px;}
.select_format_template_ftr ul li{color:#ccc;}
.select_format_template_ftr a{color:#ccc;}
.select_format_template_mar_tp25{margin-top:25px;}
/*Select Format Template End*/

@media only screen and (max-width : 1024px) {
	/*.off01_bx01_bx01_bx01_bt_bx01{height:160px;}*/
	.off01_bx01_bx01_bx01_bt_bx01 p.alt01{margin-top:15px;}
	
	.off02_bx01_bx01_bx01_bt_bx01 p.alt01{margin-top:15px;}
	
	
}

@media only screen and (max-width : 992px) {

}


@media (max-width: 768px) {
	/*.off01_bx01_bx01_bx01_tp_bx01{height:50px;}
	.off01_bx01_bx01_bx01_bt_bx01{height:150px;}*/
	
	.off08_bx01_bx01_bx01_bx03 img.alt01{width:15px;height:15px;}
}

@media (max-width:481px) {
	/*.off01_bx01_bx01{padding:15px;}
	.off01_bx01_bx01_bx01_tp{height:35px;}
	.off01_bx01_bx01_bx01_tp_bx01{display:none;}
	.off01_bx01_bx01_bx01_tp_bx02{margin-top:6px;}
	.off01_bx01_bx01_bx01_tp_bx03{margin-top:6px;}
	.off01_bx01_bx01_bx01_bt_bx01{display:none;}*/
	
	.off01_bx01_bx01_bx01_tp_bx01{font-size:16px;}	
	.off01_bx01_bx01_bx01_tp_bx01 p.alt01{margin:16px 0 0 0;}
	.off01_bx01_bx01_bx01_tp_bx03 {padding:0;}
	.off01_bx01_bx01_bx01_tp_bx03 img.alt01{width:21px;height:21px;}
	
	
	
	.off01_bx01_bx01_bx01_bt_bx01{font-size:16px;}
	.off01_bx01_bx01_bx01_bt_bx04 {padding:0;}
	.off01_bx01_bx01_bx01_bt_bx04 img.alt01{width:21px;height:21px;}
	.off01_bx01_bx01_bx01_bt_bx04 img.alt02{width:21px;height:21px;}
	
	.off02_bx01_bx01_bx01_bt_bx01{font-size:16px;}
	.off02_bx01_bx01_bx01_bt_bx04 {padding:0;}
	.off02_bx01_bx01_bx01_bt_bx04 img.alt01{width:21px;height:21px;}
	.off02_bx01_bx01_bx01_bt_bx04 img.alt02{width:21px;height:21px;}
	
	.off04_bx01_bx01_bx01_bx01{width:auto;}
	
	.mt-15{margin-top:15px;}
	
	.wd-100{width:100%;}
	
	.off07_bx01_bx01_bx01_bx01,.off07_bx01_bx01_bx01_bx02,.off07_bx01_bx01_bx01_bx03{width:100%;}
}
